<template>
  <div>
    <ImageHeader
      text="Registrieren"
      imageUrl="http://h-team.torbara.com/images/soccer/page-header-bg.jpg"
    />
    <div class="container">
      <section class="info-container">
        <InfoBox
          text="Sie sind bereits registriert?"
          linkText="Hier einloggen"
          link="#/login"
          messageType="info"
        />
      </section>

      <section class="register-form">
        <div class="col-lg-12">
          <h4>Registrieren</h4>
        </div>

        <div class="col-lg-12 form-container">
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Loginname <i>*</i></span>
                    <input type="text" name="Loginname" />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Email <i>*</i></span>
                    <input type="email" name="Email" />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Passwort <i>*</i></span>
                    <input type="password" name="password" />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Passwort wiederholen<i>*</i></span>
                    <input type="password" name="password" />
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <Button text="Registrieren" icon="fas fa-sign-in-alt" />
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/app/components/image-header.vue";
import InfoBox from "@/app/components/info-box.vue";
import Button from "@/app/components/button.vue";
export default {
  components: {
    ImageHeader,
    InfoBox,
    Button,
  },
};
</script>

<style scoped>
.info-container {
  margin: 10px;
}
</style>
